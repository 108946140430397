
import { PLAOptions } from "@/helpers/selectOptions";
import { StorageFormStatus } from "@/helpers/storageFormBaseInterface";
import Vue from "vue";

export default Vue.extend({
  props: {
    selected: {
      default: "",
      type: String
    },
    platform: {
      default: "changeRequests",
      type: String
    }
  },
  methods: {
    changeStatus(action: StorageFormStatus): void {
      this.$emit("changeStatus", action);
    }
  },
  computed: {
    statusOptions(): { label: string; value: string }[] {
      if (this.platform === "changeRequests") {
        return [
          {
            label: "Not Submitted",
            value: "Not Submitted"
          },
          {
            label: "Submitted",
            value: "Submitted"
          },
          {
            label: "Received",
            value: "Received"
          },
          {
            label: "Issued",
            value: "Issued"
          }
        ];
      } else if (this.platform === "quotes") {
        return [
          {
            label: "Draft",
            value: "draft"
          },
          { label: "Not Submitted", value: "not-submitted" },
          {
            label: "Submitted",
            value: "submitted"
          },
          {
            label: "Received",
            value: "received"
          },
          {
            label: "Rejected",
            value: "rejected"
          }
        ];
      } else if (this.platform === "plApplications") {
        return PLAOptions;
      }
      return [];
    }
  }
});
